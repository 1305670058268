import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  openQuickLook() {
    const quick_look_language_picker = document.getElementById("quick-look-language-picker");
    if (quick_look_language_picker) {
    quick_look_language_picker.hidden = false;
    }
  }

  closeQuickLook() {
    const quick_look_language_picker = document.getElementById("quick-look-language-picker");
    if (quick_look_language_picker) {
      quick_look_language_picker.hidden = true;
    }
  }
}