import { Controller } from "@hotwired/stimulus";
import { setCurrentHour, setCurrentDay } from "../../utils/calendarUtils";
import * as Luxon from "luxon";
import { getOffsetNamesForTimezone } from "../../utils/dates-and-times";

export default class extends Controller {
  // This is a list of all the elements that this controller will use.
  // They are in the view file, and are referenced by the data-week-view-target attribute.
  static targets = [
    "previousButton",
    "nextButton",
    "title",
    "eventTemplate",
    "eventContainer",
    "container",
    "loadingContainer",
  ];

  // Runs when the controller is first connected to the element.
  connect() {
    // Ui changes that need to be made when the controller first loads
    this.beginListeningForArgumentChanges();
    this.moveStartDateToStartOfWeek();
    this.highlightCurrentHour();
    let transmissionType = document.querySelector(
      'input[name="gearbox_preference"]'
    );
    if (transmissionType) {
      transmissionType = transmissionType.value;
      if (transmissionType == "automatic") {
        this.automaticRadioTarget.setAttribute(
          "data-driving-school-booking-form-target",
          "automaticRadio"
        );
        this.filter_on_automatic_transmission();
      } else if (transmissionType == "manual") {
        this.manualRadioTarget.setAttribute(
          "data-driving-school-booking-form-target",
          "manualRadio"
        );
        this.filter_on_manual_transmission();
      }
    }
  }

  // XXX
  showLoader() {
    optionalChaining(() =>
      this.targets.find("container").classList.add("hidden")
    );
    optionalChaining(() =>
      this.targets.find("loadingContainer").classList.remove("hidden")
    );

    const errorMessageElement = this.element.querySelector(
      "#week-view-error-message"
    );
    if (errorMessageElement) {
      errorMessageElement.hidden = true;
    }
  }

  // XXX
  hideLoader() {
    optionalChaining(() =>
      this.targets.find("container").classList.remove("hidden")
    );
    optionalChaining(() =>
      this.targets.find("loadingContainer").classList.add("hidden")
    );
  }

  convertTimeTo12HourFormat(time, mins) {
    // console.log(time, mins)
    let [hours, minutes] = time.match(/(\d+)(\D+)/).slice(1);
    hours = parseInt(hours);
    let isPM = minutes.toLowerCase() === "pm";

    if (hours === 0) {
      hours = 12;
    } else if (hours > 12) {
      hours -= 12;
      isPM = true;
    } else if (hours === 12) {
      isPM = true;
    }

    if (mins) {
      minutes = parseInt(mins);
    } else {
      minutes = 0;
    }

    const amPm = isPM ? "PM" : "AM";
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}${amPm}`;
  }

  eventClicked(event) {
    const slotDayId = this.data.get("pickedSlotDayFilledId");
    const slotTimeId = this.data.get("pickedSlotTimeFilledId");
    const slotIsoTimeId = this.data.get("pickedSlotIsoTimeFilledId");
    const slotDurationId = this.data.get("pickedSlotDurationFilledId");
    const pickedSlotInstructorIds = this.data.get("pickedSlotInstructorIds");
    const pickedSlotVehicleIds = this.data.get("pickedSlotVehicleIds");

    if (!slotDayId || !slotTimeId || !slotIsoTimeId) {
      console.log("Could not find the hidden fields for the day and time 1");
      // return;
    }

    // Sets the values of the hidden fields which will be used for the form
    let timeElement = document.getElementById(slotTimeId);
    let dayElement = document.getElementById(slotDayId);
    let isoTimeElement = document.getElementById(slotIsoTimeId);
    let durationElement = document.getElementById(slotDurationId);
    let instructorIdsElement = document.getElementById(pickedSlotInstructorIds);
    let vehicleIdsElement = document.getElementById(pickedSlotVehicleIds);

    if (!timeElement || !dayElement || !isoTimeElement) {
      console.log(
        "Could not find the hidden fields for the day and time elements 2"
      );
      // return;
    }

    const clickedElement = event.target.parentElement.dataset.timeHours
      ? event.target.parentElement
      : event.target;

    const timeHours = clickedElement.dataset.timeHours;
    const timeMins = clickedElement.dataset.timeMins;
    const timeIso = clickedElement.dataset.startIsoTime;
    const duration = clickedElement.dataset.duration;
    const instructorIds = clickedElement.dataset.instructorIds;
    const vehicleIds = clickedElement.dataset.vehicleIds;

    if (timeElement)
      timeElement.value = this.convertTimeTo12HourFormat(timeHours, timeMins);

    if (dayElement) dayElement.value = clickedElement.dataset.startDateTime;

    if (isoTimeElement) {
      isoTimeElement.value = timeIso;
      isoTimeElement.dispatchEvent(
        new Event("change", {
          bubbles: true,
          cancelable: true,
        })
      );
    }

    if (durationElement) {
      durationElement.value = duration;
      durationElement.dispatchEvent(
        new Event("change", {
          bubbles: true,
          cancelable: true,
        })
      );
    }

    if (instructorIdsElement) {
      instructorIdsElement.value = instructorIds;
      instructorIdsElement.dispatchEvent(
        new Event("change", {
          bubbles: true,
          cancelable: true,
        })
      );
    }

    if (vehicleIdsElement) {
      vehicleIdsElement.value = vehicleIds;
      vehicleIdsElement.dispatchEvent(
        new Event("change", {
          bubbles: true,
          cancelable: true,
        })
      );
    }

    if (
      clickedElement.dataset.preSelected == "true" &&
      clickedElement.dataset.preSelected != undefined &&
      document.getElementById("reserved_lesson_id")
    ) {
      document.getElementById("reserved_lesson_id").value =
        clickedElement.dataset.reservedLessonId;
    } else if (document.getElementById("reserved_lesson_id")) {
      document.getElementById("reserved_lesson_id").value = "";
    }

    // Removes the active class from all elements
    const elements = document.querySelectorAll(".Calendar-Event--Selected");
    elements.forEach((element) => {
      element.classList.remove("Calendar-Event--Selected");
    });

    // Sets the element as an active one
    optionalChaining(() =>
      clickedElement.classList.add("Calendar-Event--Selected")
    );
    if (this.data.get("hiddenButtonId")) {
      optionalChaining(() =>
        document
          .getElementById(this.data.get("hiddenButtonId"))
          .classList.remove("hidden")
      );
    }
  }

  previousWeek() {
    // Change the data-week-view-post-parameter-start-date attribute on the element.
    // This will trigger the MutationObserver to refresh the view.
    this.moveStartDateToStartOfWeek();
    const timezone = this.data.get("timezone") || "Europe/London";
    const startDateString = this.data.get("postParameterStartDate");
    let startDate = startDateString
      ? Luxon.DateTime.fromISO(startDateString)
      : Luxon.DateTime.now();
    startDate = startDate.setZone(timezone);
    startDate = startDate.minus({ days: 7 });
    this.data.set("postParameterStartDate", startDate.toISO());
    this.moveStartDateToStartOfWeek();
    this.updateCalendarDateWeekView(this.data.get("schoolToken"));
  }

  nextWeek() {
    // Change the data-week-view-post-parameter-start-date attribute on the element.
    // This will trigger the MutationObserver to refresh the view.
    this.moveStartDateToStartOfWeek();
    const timezone = this.data.get("timezone") || "Europe/London";
    const startDateString = this.data.get("postParameterStartDate");
    let startDate = startDateString
      ? Luxon.DateTime.fromISO(startDateString)
      : Luxon.DateTime.now();
    startDate = startDate.setZone(timezone);
    startDate = startDate.plus({ days: 7 });
    this.data.set("postParameterStartDate", startDate.toISO());
    this.moveStartDateToStartOfWeek();
    this.updateCalendarDateWeekView(this.data.get("schoolToken"));
  }

  /**
   * Begins listening for changes to the arguments passed into this controller.
   * Data is passsed into this controller via data-argument-name attributes on the element.
   * Whenever one of these changes, we need to refresh this view.
   */
  beginListeningForArgumentChanges() {
    // Create a new MutationObserver instance, it will listen to changes in the attributes of the element.
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        // Only refresh the view if the attribute that changed was one of the data attributes
        if (
          mutation.type === "attributes" &&
          mutation.attributeName &&
          mutation.attributeName.startsWith("data-")
        ) {
          this.showLoader();
          this.deleteAllEvents();
          // We need to refresh the view, but not right away. We want to wait until the user has finished typing.
          // So we set a timeout, and if the user types again before the timeout is up, we cancel the timeout and start again.
          if (this.apiCallTimeout) {
            clearTimeout(this.apiCallTimeout);
          }
          this.apiCallTimeout = setTimeout(async () => {
            await this.refreshView(this.apiCallTimeout);
            var inputElement = document.getElementById("first_time_load");
            if (
              inputElement &&
              inputElement.value === "true" &&
              this.data.get("postParameterFirstTimeLoad") == "false"
            ) {
              inputElement.value = "false";
            } else {
              this.hideLoader();
            }
            console.log("Refreshed view");
          }, 1000);
        }
      }
    });
    // Configure the MutationObserver to listen for attribute changes
    const config = { attributes: true, attributeOldValue: true };
    // Start observing the target element
    observer.observe(this.element, config);
  }
  // Used buy the method above to keep track of the timeout so it casn be cancelled.
  apiCallTimeout = null;

  /**
   * Deletes all events and then refreshes the view.
   * @returns
   */
  async refreshView(apiCallTimeout) {
    this.deleteAllEvents();
    const response = await this.makeCallToApi();
    console.log(response);

    // Might have received a request for new info since the timeout was set.
    if (this.apiCallTimeout !== apiCallTimeout) {
      return;
    }

    if (response.errorMessage) {
      const errorMessageElement = this.element.querySelector(
        "#week-view-error-message"
      );
      if (errorMessageElement) {
        errorMessageElement.textContent = response.errorMessage;
        errorMessageElement.hidden = false;
      }
      return;
    }

    this.displayWeekView();
    this.populateEvents(response.events);
    this.populate_weekday_labels(response.day_labels);
    this.setStartDate(response.start_date);
    this.setTitle(response.title);

    setTimeout(async () => {
      await this.scrollToCurrentTime();
      await this.clickPreSelectedSlot();
      this.displayNoSlotAvailable(response.future_potentials_slots);
      console.log("scroll view");
    }, 100);
  }

  displayNoSlotAvailable(future_potentials_slots) {
    const isAdmin = this.data.get("isAdmin") == "true";
    const noSlot = document.getElementById("no_slot_available");
    const noSlotAdmin = document.getElementById("no_slot_available_admin");
    const weekView = document.getElementById("week-view");
    if (future_potentials_slots) {
      if (noSlot && !isAdmin) noSlot.hidden = true;
      if (noSlotAdmin && isAdmin) noSlotAdmin.hidden = true;
      if (weekView) weekView.hidden = false;
    } else {
      if (noSlot && !isAdmin) noSlot.hidden = false;
      if (noSlotAdmin && isAdmin) noSlotAdmin.hidden = false;
      if (weekView) weekView.hidden = true;
    }
  }

  displayWeekView() {
    const weekView = document.getElementById("week-view");
    if (weekView) weekView.hidden = false;
  }

  scrollToCurrentTime() {
    const calendarDiv = document.querySelector(".Calendar");
    calendarDiv.scrollTop = 500;
  }

  setTitle(title) {
    this.titleTarget.innerHTML = title;
  }

  setStartDate(startDate) {
    const timezone = this.data.get("timezone") || "Europe/London";

    let luxonStartDate = Luxon.DateTime.fromISO(startDate);

    if (!luxonStartDate.isValid) {
      console.warn(
        `Invalid startDate: ${startDate}, falling back to current date.`
      );
      luxonStartDate = Luxon.DateTime.now().setZone(timezone);
    }

    luxonStartDate = luxonStartDate.setZone(timezone);

    let schoolToken = this.data.get("schoolToken");

    if (!schoolToken) {
      console.error("schoolToken is not defined or invalid.");
      return;
    }

    const targetElement = document.getElementById(
      `calendar-date-${schoolToken}`
    );

    if (targetElement) {
      const formattedDate = `${luxonStartDate.toLocaleString({
        month: "short",
      })} ${luxonStartDate.year}, Week ${luxonStartDate.weekNumber}`;
      targetElement.innerHTML = formattedDate;
    }
  }

  clickPreSelectedSlot() {
    const preSelectedSlot = document.querySelector(
      "[data-pre-selected='true']"
    );
    if (preSelectedSlot) {
      preSelectedSlot.click();
    }
  }

  /**
   * Calls the API specified on this controller and
   * returns the response as JSON.
   */
  async makeCallToApi() {
    const postParameters = this.getPostParameters();
    console.log(postParameters);

    const slotDay = optionalChaining(
      () => document.getElementById("picked_slot_day").value || null
    );
    const slotTime = optionalChaining(
      () => document.getElementById("picked_slot_time").value || null
    );
    const pickedSlotDurationTime = optionalChaining(
      () => document.getElementById("picked_slot_duration_time").value || null
    );

    var url = this.data.get("eventsUrl");
    if (slotDay && slotTime) {
      url = `${url}?picked_slot_day=${slotDay}&picked_slot_time=${slotTime}&picked_slot_duration_time=${pickedSlotDurationTime}`;
    }
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        ...postParameters,
      }),
    });
    if (response.ok) {
      const responseBody = await response.text();
      const asJson = JSON.parse(responseBody);
      if (this.data.get("postParameterFirstTimeLoad") == "true") {
        this.data.set("postParameterFirstTimeLoad", "false");
        this.data.set("postParameterStartDate", asJson.start_date);
      }
      return asJson;
    } else {
      return { errorMessage: "Something went wrong" };
    }
  }

  /**
   * Gets all the post parameters passed into this controller
   * via data-post-paramter-name attributes on the element.
   * @returns {Object} An object containing the post parameters
   */
  getPostParameters() {
    const postParameters = {};
    // Iterate over all data attributes and add any with the correct prefix
    Array.from(this.element.attributes).forEach((attribute) => {
      if (attribute.name.startsWith("data-")) {
        const key = attribute.name
          .substring(5)
          .replace(/-./g, (match) => match.charAt(1).toUpperCase());
        const value = attribute.value;
        if (key.startsWith("weekViewPostParameter")) {
          let postParameterKey = key.substring(21);
          // console.log("Post parameter key:", postParameterKey);
          // Make the first letter lowercase
          postParameterKey =
            postParameterKey.charAt(0).toLowerCase() +
            postParameterKey.slice(1);
          // Make the key into snake case, searching for capitals and numbers
          postParameterKey = postParameterKey
            .replace(/([A-Z0-9])/g, "_$1")
            .toLowerCase();
          postParameters[postParameterKey] = value;
        }
      }
    });
    return postParameters;
  }

  /**
   * Deletes all the events from the calendar.
   */
  deleteAllEvents() {
    this.targets.find("eventContainer").innerHTML = "";
  }

  /**
   * Adds the events to the calendar.
   * @param {*} items
   */
  populateEvents(items) {
    const timezone = this.data.get("timezone") || "Europe/London";
    let now = Luxon.DateTime.now();
    now.setZone(timezone);

    const today = now.startOf("day");
    const tomorrow = today.plus({ day: 1 }).startOf("day");

    let earliestTime = 24;
    let latestTime = 0;

    items.forEach((item) => {
      const startDateTime = Luxon.DateTime.fromISO(item.full_date).setZone(
        timezone
      );
      const clonedTemplate = this.targets
        .find("eventTemplate")
        .content.cloneNode(true);

      if (
        (startDateTime.toMillis() > today.toMillis() &&
          this.data.get("isAdmin") == "false" &&
          startDateTime.toMillis() > tomorrow.toMillis()) ||
        (startDateTime.toMillis() > today.toMillis() &&
          this.data.get("isAdmin") == "true") ||
        item.current_lesson == true ||
        this.data.get("showPastSlots") == "true"
      ) {
        // Title should be in format similar to: "1:40pm"
        let title = startDateTime
          .toLocaleString({ hour: "numeric", minute: "numeric", hour12: true })
          .toLowerCase()
          .replace(" ", "");

        // replace 0: with 12:
        if (title.startsWith("0:")) {
          title = title.replace("0:", "12:");
        }

        clonedTemplate.querySelector(".Calendar-Event-Title").textContent =
          title;

        if (this.data.get("isAdmin") == "true") {
          const slot = item.number_of_available_slots > 1 ? "slots" : "slot";
          clonedTemplate.querySelector(
            ".Calendar-Slots"
          ).textContent = `${item.number_of_available_slots} ${slot}`;

          const openToNewStudents = item.open_to_new_students == true;
          const openToExistingStudents = item.open_to_existing_students == true;

          // if open to both show "New Students | Existing Students" else show "New Students" or "Existing Students"
          if (openToNewStudents && openToExistingStudents) {
            clonedTemplate.querySelector(".Calendar-Open-To").textContent =
              "New Students | Existing Students";
          }
          if (openToNewStudents && !openToExistingStudents) {
            clonedTemplate.querySelector(".Calendar-Open-To").textContent =
              "New Students";
          }
          if (!openToNewStudents && openToExistingStudents) {
            clonedTemplate.querySelector(".Calendar-Open-To").textContent =
              "Existing Students";
          }
        } else {
          clonedTemplate.querySelector(".Calendar-Slots").textContent = "";
        }

        if (this.data.get("isAdmin") == "true" && item.pre_selected == true) {
          clonedTemplate.querySelector(
            ".Calendar-Slots"
          ).textContent = `${item.instructor_name}`;
        }

        const getHoursNumberToTime = (hoursNumber) => {
          var ampm = hoursNumber >= 12 ? "pm" : "am";
          hoursNumber = hoursNumber % 12;
          hoursNumber = hoursNumber ? hoursNumber : 12; // If hours is 0 then make it 12.
          var strHours = hoursNumber < 10 ? "0" + hoursNumber : hoursNumber; // If hours is less than 10, prefix a '0'.
          return strHours + ampm;
        };

        earliestTime = Math.min(earliestTime, startDateTime.hour - 1);
        latestTime = Math.max(latestTime, startDateTime.hour + 1);

        // console.log(item);
        clonedTemplate
          .querySelector(".Calendar-Event")
          .setAttribute("data-day", item.day);

        clonedTemplate
          .querySelector(".Calendar-Event")
          .setAttribute("data-duration", item.duration);
        clonedTemplate
          .querySelector(".Calendar-Event")
          .setAttribute(
            "data-time-hours",
            getHoursNumberToTime(startDateTime.hour)
          );
        clonedTemplate
          .querySelector(".Calendar-Event")
          .setAttribute(
            "data-time-mins",
            startDateTime.minute.toString().padStart(2, "0")
          );
        clonedTemplate
          .querySelector(".Calendar-Event")
          .setAttribute("data-start-date-time", item.start_date_time);
        clonedTemplate
          .querySelector(".Calendar-Event")
          .setAttribute("data-start-iso-time", item.full_date);

        clonedTemplate
          .querySelector(".Calendar-Event")
          .setAttribute("data-zone-ids", item.zone_ids);

        clonedTemplate
          .querySelector(".Calendar-Event")
          .setAttribute("data-instructor-ids", item.instructor_ids);

        clonedTemplate
          .querySelector(".Calendar-Event")
          .setAttribute("data-vehicle-ids", item.vehicle_ids);

        if (item.ineligible) {
          optionalChaining(() =>
            clonedTemplate
              .querySelector(".Calendar-Event")
              .classList.add("Calendar-Event--ineligible")
          );
        }

        if (item.pre_selected == true) {
          clonedTemplate
            .querySelector(".Calendar-Event")
            .setAttribute("data-pre-selected", "true");
          clonedTemplate
            .querySelector(".Calendar-Event")
            .setAttribute("data-reserved-lesson-id", item.reserved_lesson_id);
        }

        this.targets.find("eventContainer").appendChild(clonedTemplate);
      }
    });

    earliestTime = Math.min(7, Math.max(earliestTime, 0));
    latestTime = Math.max(18, Math.min(latestTime, 24));

    // Calendar--weeklySlots

    const weeklySlotsElement = this.element.getElementsByClassName(
      "Calendar--weeklySlots"
    );
    console.log(weeklySlotsElement);
    [...weeklySlotsElement].forEach((element) => {
      console.log(element);
    });
    if (weeklySlotsElement.item(0)) {
      let styleString = "auto 30px ";
      for (let i = 0; i <= 24; i++) {
        if (i >= earliestTime && i <= latestTime) {
          styleString += "60px ";
        } else {
          styleString += "0px ";
        }
      }
      weeklySlotsElement.item(0).style.gridTemplateRows = styleString;
    }
  }

  populate_weekday_labels(weekdays) {
    const timezone = this.data.get("timezone") || "Europe/London";
    const startDateString = this.data.get("postParameterStartDate");

    let startDate = startDateString
      ? Luxon.DateTime.fromISO(startDateString)
      : Luxon.DateTime.now();
    startDate = startDate.setZone(timezone);

    const now = Luxon.DateTime.now().setZone(timezone);

    weekdays = [];

    let currentIndex = -1;
    const timezonesUsed = [];

    for (let i = 0; i < 7; i++) {
      let day = startDate.plus({ days: i });

      const timeZone = day.offsetNameShort;
      if (timeZone && !timezonesUsed.includes(timeZone)) {
        timezonesUsed.push(timeZone);
      }

      weekdays.push(
        day.toLocaleString({ weekday: "short" }) +
          " " +
          day.day.toString().padStart(2, "0")
      );

      if (
        day.day == now.day &&
        day.month == now.month &&
        day.year == now.year
      ) {
        currentIndex = i;
      }
    }

    [...this.element.getElementsByClassName("Calendar-Timezone")].forEach(
      (element) => {
        element.textContent = timezonesUsed.join(" / ");
      }
    );

    [...this.element.getElementsByClassName("Calendar-WeekDay")].forEach(
      (element, index) => {
        optionalChaining(() => element.classList.remove("isCurrently"));
        element.textContent = weekdays[index];
        if (index == currentIndex) {
          element.classList.add("isCurrently");
        }
      }
    );
  }

  filter_on_instructor_id_event(event) {
    const id = event.detail.id;
    console.log("Instructor ID:", id);
    this.data.set("postParameterInstructorId", id);
  }

  filter_on_instructor_id(event) {
    const id = event.currentTarget.dataset.instructorId;
    this.data.set("postParameterInstructorId", id);
  }

  filter_on_automatic_transmission() {
    this.data.set("postParameterTransmission", "automatic");
  }

  filter_on_manual_transmission() {
    this.data.set("postParameterTransmission", "manual");
  }

  highlightCurrentHour() {
    const timezone = this.data.get("timezone") || "Europe/London";
    let now = Luxon.DateTime.now();
    now.setZone(timezone);
    const hourElements = this.element.querySelectorAll(".Calendar-Hour");
    hourElements.forEach((el) => {
      const text = el.textContent.trim();
      const hourParts = text.match(/(\d+)(AM|PM)/);
      let hour = parseInt(hourParts[1], 10);
      if (hourParts[2] === "PM" && hour !== 12) {
        hour += 12;
      } else if (hourParts[2] === "AM" && hour === 12) {
        hour = 0;
      }

      if (hour === now.hour) {
        el.classList.add("isCurrently");
      } else {
        el.classList.remove("isCurrently");
      }
    });
  }

  /**
   * Updates the title that displays the month and week of the year
   */
  async updateCalendarDateWeekView(schoolToken) {
    const timezone = this.data.get("timezone") || "Europe/London";
    const startDateString = this.data.get("postParameterStartDate");
    let startDate = startDateString
      ? Luxon.DateTime.fromISO(startDateString)
      : Luxon.DateTime.now();
    startDate = startDate.setZone(timezone);
    const targetElement = document.getElementById(
      `calendar-date-${schoolToken}`
    );
    if (targetElement) {
      const formattedDate = `${startDate.toLocaleString({ month: "short" })} ${
        startDate.year
      }, Week ${startDate.weekNumber}`;
      targetElement.innerHTML = formattedDate;
    }
  }

  /**
   * Takes the current start date parameter (or now if none is set)
   * and moves it to be the start of the week in browser local time,
   * so that slots for the whole week are shown.
   */
  moveStartDateToStartOfWeek() {
    const timezone = this.data.get("timezone") || "Europe/London";
    const startDateString = this.data.get("postParameterStartDate");

    let startDate = startDateString
      ? Luxon.DateTime.fromISO(startDateString)
      : Luxon.DateTime.now();
    startDate = startDate.setZone(timezone);
    startDate = startDate.startOf("week");

    if (startDateString !== startDate.toISO()) {
      this.data.set("postParameterStartDate", startDate.toISO());
    }
  }
}

function optionalChaining(valueFn) {
  try {
    return valueFn();
  } catch (e) {
    return undefined;
  }
}
