// driving_school_classroom_program_setup_controller.js
import { Controller } from "stimulus";

export default class extends Controller {

  async showQuickLook(event) {
    event.preventDefault();

    const sessionId = this.data.get("sessionId");

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    const response = await fetch(`/schools/classroom/sessions/${sessionId}/quick-look`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRF-Token": csrfToken,
        "Accept": "text/vnd.turbo-stream.html"
      }
    });

    if (response.ok) {
      const quickViewElement =
        document.querySelector("#school-classrooms-quick-look") ||
        document.querySelector("#school_classrooms_quick_look");
      
      if (!quickViewElement) {
        console.error("Quick view element not found in the DOM.");
        return;
      }

      quickViewElement.classList.remove("u-hidden");

      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");

      if (template) {
        quickViewElement.innerHTML = "";
        quickViewElement.appendChild(template.content.cloneNode(true));
      }
    }
  }
  
  closeQuickLook(event) {
    event.preventDefault();
    
    const quickViewElement =
      document.querySelector("#school-classrooms-quick-look") ||
      document.querySelector("#school_classrooms_quick_look");

    if (!quickViewElement) {
      console.error("Quick view element not found in the DOM.");
      return;
    }

    quickViewElement.classList.add("u-hidden");
  }

  async showEnrollQuickLook(event) {
    event.preventDefault();

    const programId = this.data.get("programId");
    
    const quickViewElement = document.querySelector("#school-classrooms-quick-look");
    quickViewElement.classList.remove("u-hidden");
    
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    const response = await fetch(`/schools/classroom/program/${programId}/quick-look/enroll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRF-Token": csrfToken,
        "Accept": "text/vnd.turbo-stream.html"
      }
    });

    if (response.ok) {

      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const targetElement = document.getElementById("school-classrooms-quick-look");

      if (template && targetElement) {
        targetElement.innerHTML = "";
        targetElement.appendChild(template.content.cloneNode(true));
      }
    }
  }

  validate(event) {
    const errors = this.validateForm();
    
    if (errors.length > 0) {
      event.preventDefault();
      this.showValidationBlock(errors.join("<br>"));
      return;
    }    
  }

  validateForm() {
    let validationErrors = [];

    let fieldsToValidate = [
      { id: "driving_school_classroom_session_start_time", name: "Start time", validation: "required|datetime" },
      { id: "driving_school_classroom_session_max_number_of_in_person_attendees", name: "Max in-person attendees", validation: "required|positiveNumber" },
      { id: "driving_school_classroom_session_max_number_of_remote_attendees", name: "Max remote attendees", validation: "required|positiveNumber" }
    ];

    fieldsToValidate.forEach(field_data => {
      let field = document.getElementById(field_data.id);
      
      if (field) {
        let value = field.value;
        let validationRules = field_data.validation.split('|');
  
        for (let rule of validationRules) {
          switch (rule) {
            case 'required':
              if (!value) {
                validationErrors.push(`${field_data.name} is required.`);
                return;
              }
              break;
  
            case 'datetime':
              if (value && isNaN(Date.parse(value))) {
                validationErrors.push(`${field_data.name} must be a valid date-time.`);
                return;
              }
              break;
  
            case 'positiveNumber':
              if (value === "") {
                validationErrors.push(`${field_data.name} cannot be empty.`);
                return;
              } else if (isNaN(value) || Number(value) <= 0) {
                validationErrors.push(`${field_data.name} must be greater than 0.`);
                return;
              }
              break;
          }
        }
      } else {
        console.log(`Field with id ${field_data.id} not found.`);
      }
    });
    
    return validationErrors;
  }

  showValidationBlock(message) {
    const validationBlock = document.getElementById("validation-block");
    const validationMessage = document.getElementById("validation-message");
  
    validationMessage.innerHTML = message;
    validationBlock.hidden = false;
    validationMessage.hidden = false;
  }
}
