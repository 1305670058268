import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "generateButton", 
    "form",
    "startDate", 
    "endDate", 
    "errorMessage",
    "successMessage",
    "typeOptions",
    "certificateSection",
    "periodBlock"
  ];

  connect() {
    this.hasError = false;
    this.updateButtonState();
    this.toggleCertificateSection();
  }

  areDatesValid(startDate, endDate) {
    return startDate && endDate && startDate <= endDate;
  }

  updateButtonState() {
    const startDateValue = this.startDateTarget.value;
    const endDateValue = this.endDateTarget.value;
    const isOutstandingLessonsOwed = this.typeOptionsTarget.value === 'outstanding_lessons_owed';

    // Skip date validation for outstanding_lessons_owed
    if (isOutstandingLessonsOwed) {
      this.generateButtonTarget.disabled = false;
      this.setErrorState(false);
      return;
    }

    // Original date validation logic
    if (this.areDatesValid(startDateValue, endDateValue)) {
      this.generateButtonTarget.disabled = false;
      this.setErrorState(false);
    } else {
      this.generateButtonTarget.disabled = true;
      if (startDateValue && endDateValue) {
        this.setErrorState(true);
      } else {
        this.setErrorState(false);
      }
    }
  }

  handleFormSubmit(event) {
    if (this.hasError) {
      event.preventDefault();
      return;
    }

    this.generateButtonTarget.disabled = true;
    this.showSuccessMessage();

    setTimeout(() => {
      this.generateButtonTarget.disabled = false;
      this.hideSuccessMessage();
    }, 5000);
  }

  setErrorState(hasError) {
    this.errorMessageTarget.hidden = !hasError;
    this.hasError = hasError;
  }

  showSuccessMessage() {
    this.successMessageTarget.hidden = false
  }

  hideSuccessMessage() {
    this.successMessageTarget.hidden = true
  }

  showErrorMessage() {
    this.setErrorState(true);
  }

  hideErrorMessage() {
    this.setErrorState(false);
  }

  toggleParameterSection() {

    const selectedOption = this.typeOptionsTarget.value;

    // Define visibility rules for each section based on selected option
    const visibilityRules = {
      certificate_of_completion: {
        certificateSection: true,
        periodBlock: true
      },
      outstanding_lessons_owed: {
        certificateSection: false,
        periodBlock: false
      },
      // Easy to add new options:
      // new_option: {
      //   certificateSection: false,
      //   periodBlock: true,
      //   newSection: true
      // }
    };

    // Get rules for selected option (or use default rules if option not found)
    const rules = visibilityRules[selectedOption] || {
      certificateSection: false,
      periodBlock: true
    };

    // Apply visibility rules
    this.certificateSectionTarget.hidden = !rules.certificateSection;
    this.periodBlockTarget.hidden = !rules.periodBlock;

    this.updateButtonState();

  }
}
