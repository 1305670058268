import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "tab",
    "pickedInstructorToken",
    "radio",
    "programId",
    "startTime",
    "availableToScheduleOnline",
    "descriptionSummary",
    "descriptionAgenda",
    "descriptionPrivateNotes",
    "daysSessionsRun",
    "validationBlock",
    "validationMessage",
    "saveButton"
  ];

  connect() {

  }


  submit(event) {
    event.preventDefault();
    const selectedProgram = this.getSelectedProgram();
    const pupilId = this.getPupilId();
    
    if (selectedProgram && pupilId) {
      this.postForm(selectedProgram, pupilId);
    } else {
        console.error('No program selected');
    }
  }

  getSelectedProgram() {
      const selectedRadio = this.radioTargets.find(radio => radio.checked);
      return selectedRadio ? selectedRadio.value : null;
  }

  getPupilId() {
    return document.getElementById("pupil_id").value;
  }

  async postForm(selectedProgram, pupilId) {
    try {
      const response = await fetch("/schools/classroom/program/quick-look/schedule", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
        },
        body: new URLSearchParams({ 
          selected_program: selectedProgram,
          pupil_id: pupilId
        })
      });

      if (response.ok) {
        const stream = await response.text();
        Turbo.renderStreamMessage(stream);
        this.toggleErrorPanel(false);
      } else {
        const responseBody = await response.json();
  
        if (responseBody.error) {
          const errorText = responseBody.error;
          this.toggleErrorPanel(true, errorText);
        } else {
          console.error("Unexpected error: ", responseBody);
        }
      }
    } catch (error) {
      console.error("Error fetching form:", error);
    }
  }

  updateProgram(event) {
    event.preventDefault();

    const errors = this.validateForm();

    if (errors.length > 0) {
      this.showValidationBlock(errors.join("<br>"));
      return;
    }

    const programId = event.target.dataset.drivingSchoolClassroomQuickLookProgramId;
    
    if (programId) {
      this.postEditForm(programId);
    } else {
        console.error('programId does not exist');
    }
  }
  
  async postEditForm(programId) {
    try {
      const formData = {
        driving_school_classroom_program: {
          start_time: this.startTimeTarget.value,
          available_to_schedule_online: this.availableToScheduleOnlineTarget.checked,
          description_summary_english: this.descriptionSummaryTarget.value,
          description_agenda_english: this.descriptionAgendaTarget.value,
          description_private_notes_english: this.descriptionPrivateNotesTarget.value,
          days_sessions_run: this.daysSessionsRunTargets
            .filter(target => target.checked)
            .map(target => target.value)
        }
      };

      const response = await fetch(`/schools/classroom/program/${programId}/quick-look/edit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        const stream = await response.text();
        Turbo.renderStreamMessage(stream);
      } else {
        const responseBody = await response.json();
  
        if (responseBody.error) {
          const errorText = responseBody.error;
          console.log(errorText);
          this.showValidationBlock(errorText);
        } else {
          console.error("Unexpected error: ", responseBody);
        }
      }
    } catch (error) {
      console.error("Error fetching form:", error);
    }
  }

  closeQuickLook(event) {
    event.preventDefault();
    const quickViewElement = document.querySelector("#school-classrooms-quick-look");
    quickViewElement.classList.add("u-hidden");
  }

  async showQuickLookForPupil(event) {
    event.preventDefault();

    const sessionId = this.data.get("sessionId");
    const pupilId = this.data.get("pupilId");
    const attendanceId = this.data.get("attendanceId");

    const quickViewElement = document.querySelector("#school-dashboard-lesson-quick-look");
    quickViewElement.classList.remove("u-hidden");
    
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    const response = await fetch(`/schools/classroom/sessions/${sessionId}/${attendanceId}/quick-look`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRF-Token": csrfToken,
        "Accept": "text/vnd.turbo-stream.html",
        "body": JSON.stringify({ "pupil_id": pupilId, "attendance_id": attendanceId })
      }
    });

    if (response.ok) {

      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const targetElement = document.getElementById("school_lesson_quick_look");

      if (template && targetElement) {
        targetElement.innerHTML = "";
        targetElement.appendChild(template.content.cloneNode(true));
      }
    }
  }

  async showQuickLook(event) {
    event.preventDefault();

    const sessionId = this.data.get("sessionId");

    const quickViewElement = document.querySelector("#school-dashboard-lesson-quick-look");
    quickViewElement.classList.remove("u-hidden");
    
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    const response = await fetch(`/schools/classroom/sessions/${sessionId}/quick-look/dashboard`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRF-Token": csrfToken,
        "Accept": "text/vnd.turbo-stream.html"
      }
    });

    if (response.ok) {

      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const targetElement = document.getElementById("school_lesson_quick_look");

      if (template && targetElement) {
        targetElement.innerHTML = "";
        targetElement.appendChild(template.content.cloneNode(true));
      }
    }
  }

  async showEditQuickLook(event) {
    event.preventDefault();

    const programId = this.data.get("programId");

    const quickViewElement = document.querySelector("#school-classrooms-quick-look");

    quickViewElement.classList.remove("u-hidden");
    
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    const response = await fetch(`/schools/classroom/program/${programId}/quick-look/edit`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRF-Token": csrfToken,
        "Accept": "text/vnd.turbo-stream.html"
      }
    });

    if (response.ok) {
      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const targetElement = document.getElementById("school-classrooms-quick-look");

      if (template && targetElement) {
        targetElement.innerHTML = "";
        targetElement.appendChild(template.content.cloneNode(true));
      }
    }
  }

  toggleErrorPanel(show, errorMessage) {
    const errorSection = document.getElementById("error-section");
    const errorList = document.getElementById("errors-list");
  
    if (show) {
      errorSection.classList.remove("u-hidden");
      errorList.textContent = errorMessage || "An error occurred";
    } else {
      errorSection.classList.add("u-hidden");
      errorList.textContent = "";
    }
  }

  validateForm() {
    let validationErrors = [];

    const startTime = this.startTimeTarget?.value;

    if (!startTime) {
      validationErrors.push("Start date/time is required.");
    } else if (isNaN(Date.parse(startTime))) {
      validationErrors.push("Start date/time must be a valid date-time.");
    }

    const selectedDays = this.daysSessionsRunTargets
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.value);

    if (selectedDays.length === 0) {
      validationErrors.push("At least one day must be selected for sessions.");
    }

    return validationErrors;
  }

  showValidationBlock(message) {
    this.validationMessageTarget.innerHTML = message;
    this.validationBlockTarget.hidden = false;
    this.validationMessageTarget.hidden = false;
  }
}